import { createContext } from '@lit/context';

const styleAttribute = 'ui-component';

export class ThemeService {
  private _themeCache = new Map<string, string>();

  constructor() {
    this._cacheStyles();
  }

  /**
   * Returns the styles for the given keys
   * @param {strings[]} keys
   * @returns {string}
   */
  public get(keys: string | string[]): string {
    const partials = typeof keys === 'string' ? [keys] : keys;
    let themeStyles = '';
    for (const key of partials) {
      if (this._themeCache.has(key)) {
        themeStyles = themeStyles.concat(this._themeCache.get(key) ?? '');
        continue;
      }
      window.$app.logger.warn(`theme key '${key}' not available`);
    }
    return themeStyles;
  }

  /**
   * Get all inlined theme styles from index.html
   */
  private _cacheStyles() {
    const selector = `style[${styleAttribute}]`;
    const styles = document.querySelectorAll(selector);
    styles.forEach((el: Element) => {
      const key = el.getAttribute(styleAttribute);
      if (key === null || key === '') {
        window.$app.logger.warn('unknown ui-core style:', el.attributes);
        return;
      }
      const styles = el.innerHTML;
      this._themeCache.set(key, styles);
    });
  }
}

export const ThemeServiceContext = createContext<ThemeService>({});
