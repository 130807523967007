import { createContext } from '@lit/context';
import type { I18Options, I18nService } from './i18n/i18n-service';

export interface ValidationResult {
  id: string;
  msg: string | null;
  isValid: boolean;
}

type ValidationRule = (valueOne: string, valueTwo: string) => ValidationResult | null;

const LOWER_CASE_REGEX = /(.*[a-z].*)/;
const UPPER_CASE_REGEX = /(.*[A-Z].*)/;
const MIXED_CASE_REGEX = /(?=.*[a-z])(?=.*[A-Z])/;
const NUMBER_REGEX = /(?=.*\d)/;
const SPECIAL_CHARS_REGEX = /[!@#$%^&*()\-=+[\]{};':"|,./<>?`~]/;
const LATIN_LETTER_REGEX = /^([A-Za-z\s]*)$/;
const EMAIL_REGEX = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

export class ValidationService {
  private _validationRules: { [key: string]: ValidationRule };
  private _options?: I18Options;
  private _exactlyNYearsAgoDate = (yearsAgo: number) =>
    new Date(new Date().setFullYear(new Date().getFullYear() - yearsAgo));

  constructor(private readonly $t: I18nService) {
    this._validationRules = {
      email: (value: string) => this.createResult(EMAIL_REGEX.test(value), 'validator.email'),
      required: (value: string) => this.createResult(!!value, 'validator.required'),
      minLength: (value: string) => this.createResult(value.length > 3, 'validator.minLength'),
      minLengthName: (value: string) => this.createResult(value.length > 1, 'validator.minLengthName'),
      maxLength: (value: string) => this.createResult(value.length < 32, 'validator.maxLength'),
      maxAmount: (value: string) => this.createResult(Number(value) < 10001, 'validator.maxAmount'),
      lettersOnly: (value: string) => this.createResult(LATIN_LETTER_REGEX.test(value), 'validator.lettersOnly'),
      lowerCase: (value: string) => this.createResult(LOWER_CASE_REGEX.test(value), 'validator.lowerCase'),
      upperCase: (value: string) => this.createResult(UPPER_CASE_REGEX.test(value), 'validator.upperCase'),
      mixedCase: (value: string) => this.createResult(MIXED_CASE_REGEX.test(value), 'validator.casemix'),
      specialChars: (value: string) => this.createResult(SPECIAL_CHARS_REGEX.test(value), 'validator.specialChars'),
      numbers: (value: string) => this.createResult(NUMBER_REGEX.test(value), 'validator.numbers'),
      shouldMatch: (valueOne: string, valueTwo: string) =>
        this.createResult(valueOne === valueTwo, 'validator.shouldMatch'),
      isValidDate: (value: string) => {
        const date = value.split('-');
        const validationResult = Number(date[0]) > 1899 && this._isValidDate(value);
        return this.createResult(validationResult, 'validator.isValidDate');
      },
      isPastDate: (value: string) => this.createResult(new Date(value) <= new Date(), 'validator.isPastDate'),
      isUnderAgeLimit: (value: string) =>
        this.createResult(
          this._exactlyNYearsAgoDate(18).getTime() >= new Date(value).getTime(),
          'validator.isUnderAgeLimit',
        ),
    };
  }

  public validateField(rules: string[], valueOne: string, valueTwo = '', options?: I18Options): ValidationResult[] {
    this._options = options;
    const resultArray: ValidationResult[] = [];

    for (const ruleName of rules) {
      const rule = this._validationRules[ruleName];
      if (rule) {
        const result = rule(valueOne, valueTwo);
        if (result) {
          resultArray.push(result);
        }
      }
    }

    return resultArray;
  }

  public createResult(isValid: boolean, errorId: string): ValidationResult {
    return isValid
      ? { id: errorId, msg: '', isValid: true }
      : { id: errorId, msg: this.$t.get(errorId, this._options), isValid: false };
  }

  private _isValidDate(dateStr: string): boolean {
    const date = new Date(dateStr);

    if (Number.isNaN(date.getTime())) {
      return false;
    }

    const [inputYear, inputMonth, inputDay] = dateStr.split('-').map(Number);
    const dateYear = date.getFullYear();
    const dateMonth = date.getMonth() + 1; // Month is 0-indexed
    const dateDay = date.getDate();

    return !(inputYear !== dateYear || inputMonth !== dateMonth || inputDay !== dateDay);
  }
}

export const validationContext = createContext<ValidationService>({});
