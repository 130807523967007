import type { CallbackFn } from '../../types';

type CallbackInfo = {
  once: boolean;
};

export class Store<T> {
  // Used for mutation check in `next`.
  private _listeners: Map<CallbackFn<T>, CallbackInfo> = new Map();
  private _value: T;
  private _valueStringified: string;

  public constructor(initialValue: T) {
    this._value = initialValue;
    this._valueStringified = this._stringify(this._value);
  }

  /**
   * @param newValue
   * @param mutationCheck - set to false if `value` is an object and has properties that can not be stringified
   * (like functions, Map, Set etc.)
   */
  public next(newValue: T, mutationCheck = true): void {
    this._value = newValue;
    const valueStringified = this._stringify(this._value);
    // Skip updating subscribers if the value and its properties have not changed
    if (mutationCheck && this._canBeMutationChecked(this._value) && valueStringified === this._valueStringified) {
      window.$app.logger.log('value unchanged => skip next');
      return;
    }
    this._valueStringified = valueStringified;
    [...this._listeners.entries()].forEach(([fn, info]) => {
      fn(this._value);
      if (info.once) this._listeners.delete(fn);
    });
  }

  public subCount(): number {
    return this._listeners.size;
  }

  /**
   * @param fn - Callback function
   * @param dispatch - Set dispatch to true to immediately get an update with the current store value, if it is not undefined.
   */
  public subscribe(fn: CallbackFn<T>, dispatch?: boolean): CallbackFn<T> {
    return this._subscribe(fn, dispatch);
  }

  /**
   * @param fn - Callback function
   * @param dispatch - Set dispatch to true to immediately get an update with the current store value, if it is not undefined.
   */
  public subscribeOnce(fn: CallbackFn<T>, dispatch?: boolean): CallbackFn<T> {
    return this._subscribe(fn, dispatch, true);
  }

  public unsubscribe(fn: CallbackFn<T> | CallbackFn<T>[]): void {
    Array.isArray(fn) ? fn.forEach((f) => this._listeners.delete(f)) : this._listeners.delete(fn);
  }
  // ----------------------------------

  public get value(): T {
    return this._value;
  }

  private _stringify(object: T): string {
    try {
      return JSON.stringify(object);
    } catch (err) {
      window.$app.logger.warn('[store] failed to stringify value', err);
      return '';
    }
  }

  private _canBeMutationChecked(value: any) {
    return !(value instanceof Set || value instanceof Map || value instanceof Function);
  }

  private _subscribe(fn: CallbackFn<T>, dispatch?: boolean, once?: boolean): CallbackFn<T> {
    this._listeners.set(fn, { once: !!once });
    if (dispatch && this._value !== undefined) {
      fn(this._value);
    }
    return fn;
  }
}

// ----------------------------------

export class SubHelper {
  private subs: Map<Store<any>, ((value: any) => void)[]> = new Map();

  public addSub<T>(store: Store<T>, cb: (value: T) => void, dispatch?: boolean): void {
    if (!this.subs.has(store)) this.subs.set(store, []);
    this.subs.get(store)!.push(store.subscribe((value) => cb(value), !!dispatch));
  }

  public unsubscribeAll(): void {
    [...this.subs.entries()].forEach((e) => e[1].forEach((cb) => e[0].unsubscribe(cb)));
    this.subs = new Map();
  }
}
