import { deviceType as dType, primaryInput } from 'detect-it';

export enum DeviceType {
  MOUSE = 'mouse',
  TOUCH = 'touch',
}

export enum LayoutType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

enum _DetectedDeviceType {
  HYBRID = 'hybrid',
  MOUSE_ONLY = 'mouseOnly',
  TOUCH_ONLY = 'touchOnly',
}

export function deviceType(): DeviceType {
  return _memoizedDeviceType();
}

export function layoutType() {
  if (isMobile()) {
    return LayoutType.MOBILE;
  }
  if (isTablet()) {
    return LayoutType.TABLET;
  }
  return LayoutType.DESKTOP;
}

export function isMobile() {
  return window.matchMedia('(max-width: 47.9375rem)').matches; // 767px
}

export function isTablet() {
  return window.matchMedia('(min-width: 48rem) and (max-width: 63.9375rem)').matches; // 768px and 1023px
}

export function isDesktop() {
  return window.matchMedia('(min-width: 64rem)').matches; // 1024px
}

export function isPortrait() {
  return window.matchMedia('(orientation: portrait)').matches;
}

export function isLandscape() {
  return window.matchMedia('(orientation: landscape)').matches;
}

export function getDeviceOrientation(): 'portrait' | 'landscape' {
  const orientation = screen.orientation;

  if (orientation?.type) {
    if (orientation.type.includes('portrait')) {
      return 'portrait';
    }
    if (orientation.type.includes('landscape')) {
      return 'landscape';
    }
  }

  // Fallback for older browsers or environments without screen.orientation
  const isPortrait = window.matchMedia('(orientation: portrait)').matches;
  return isPortrait ? 'portrait' : 'landscape';
}

function _handleHybridTypes(type: _DetectedDeviceType): DeviceType {
  if (type === _DetectedDeviceType.HYBRID) {
    if (primaryInput === 'mouse') {
      window.$app.logger.log(
        `[device] set hybrid device type to: '${DeviceType.MOUSE}', based on primaryInput '${primaryInput}'`,
      );
      return DeviceType.MOUSE;
    }
    window.$app.logger.log(
      `[device] set hybrid device type to: '${DeviceType.TOUCH}', based on primaryInput '${primaryInput}'`,
    );
    return DeviceType.TOUCH;
  }

  if (type === _DetectedDeviceType.MOUSE_ONLY) {
    window.$app.logger.log(
      `[device] set device type to: '${DeviceType.MOUSE}', based on type '${_DetectedDeviceType.MOUSE_ONLY}'`,
    );
    return DeviceType.MOUSE;
  }

  window.$app.logger.log(
    `[device] set device type to: '${DeviceType.TOUCH}', based on type '${_DetectedDeviceType.TOUCH_ONLY}'`,
  );
  return DeviceType.TOUCH;
}

const _memoizedDeviceType: () => DeviceType = (() => {
  let deviceType: DeviceType | undefined;
  return () => {
    if (deviceType === undefined) {
      deviceType = _handleHybridTypes(dType as _DetectedDeviceType);
    }
    return deviceType;
  };
})();
