import { UIToasts } from '@ui-core/components/ui-toasts/ui-toasts';
import type { LitElement } from 'lit';

export type Toast = {
  autoClose: boolean;
  id: number;
  message: string;
  type: ToastType;
};

export enum ToastType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export type ToastCloseEvent = CustomEvent<{ id: number }>;

export class ToastService {
  private _activeToasts: Toast[] = [];
  private _root: LitElement | ShadowRoot;
  private _toastsEl: UIToasts;
  private _toastId = 0;

  /**
   * Show toast message
   * @param message
   * @param type
   * @param autoClose set to false to prevent auto close
   */
  public show(message: string, type: ToastType = ToastType.INFO, autoClose = true) {
    window.$app.logger.log(`show toast: '${message}' of type: ${type} (autoclose: ${autoClose})`);
    const id = this._toastId++;
    this._activeToasts.push({ id, message, type, autoClose });
    if (!this._toastsEl) {
      this._createToastsElement();
    }
    this._toastsEl.toasts = this._activeToasts;
    this._toastsEl.requestUpdate();
  }

  private _removeToast(id: number) {
    this._activeToasts = this._activeToasts.filter((toast) => toast.id !== id);
    this._toastsEl.toasts = this._activeToasts;
    this._toastsEl.requestUpdate();
    if (this._activeToasts.length < 1) {
      // TODO: remove toasts element
    }
  }

  private _createToastsElement() {
    if (!this._root) {
      this._root = document.querySelector('app-root') as LitElement;
      if (this._root === null) {
        window.$app.logger.warn('cannot select root element!', new Error('cannot select root element!'));
        return;
      }
      if (this._root.shadowRoot !== null) {
        this._root = this._root.shadowRoot;
      }
    }
    this._toastsEl = new UIToasts();
    this._toastsEl.toasts = this._activeToasts;
    this._root.append(this._toastsEl);
    this._toastsEl.addEventListener('close', (ev) => {
      const id = Number((ev as ToastCloseEvent).detail.id);
      this._removeToast(id);
    });
  }
}
