import { createContext } from '@lit/context';
import { get } from 'radash';

export type I18Options = { [key: string]: string };

export class I18nService {
  private i18n: any;

  constructor(initialTranslations: any) {
    this.i18n = initialTranslations;
  }

  /**
   * Retrieves a translation string for the given key.
   * @param key - The key for the translation string. This can be a path to a nested value.
   * @param options - Optional parameter for I18Options to inject strings in {{key}} values.
   * @returns The translation string if it exists, or the original key if a translation is not found.
   *
   * @example get('login.button.confirm')
   * @example get('login.button.withText', { field: 'continue' })
   *
   */
  public get(key: string, options?: I18Options): string {
    if (!key) {
      window.$app.logger.warn('key undefined');
      return 'KEY_UNDEFINED';
    }
    const result = get(this.i18n, key);
    let string: string | undefined;
    if (typeof result === 'string') {
      string = result;

      if (options) {
        for (const [key, value] of Object.entries(options)) {
          const regex = new RegExp(`{{(${key})}}`, 'g');
          string = string.replace(regex, value);
        }
      }
    }
    if (!string)
      window.$app.logger.warn(`Translation missing for key ${key}`, new Error(`Translation missing for key: ${key}`), {
        translation_key: key,
        get_result: result,
      });
    return string ?? key;
  }

  public switch(translations: any): void {
    this.i18n = translations;
  }
}

export const I18nServiceContext = createContext<I18nService>({});
