export function isJWTExpired(jwt?: string): boolean {
  return jwt ? getJWTExpDate(jwt).getTime() - new Date().getTime() < 10_000 : true;
}

export function getJWTExpDate(jwt: string): Date {
  try {
    return new Date(JSON.parse(window.atob(jwt.split('.')[1]!)).exp * 1000);
  } catch (err) {
    return new Date();
  }
}

export function getJWTIatDate(jwt: string): Date {
  try {
    return new Date(JSON.parse(window.atob(jwt.split('.')[1]!)).iat * 1000);
  } catch (err) {
    return new Date();
  }
}

export function getUserId(jwt?: string): string | null {
  try {
    return jwt ? JSON.parse(window.atob(jwt.split('.')[1]!)).sub : null;
  } catch (err) {
    return null;
  }
}

export function getAuthTime(jwt?: string): Date | undefined {
  try {
    return jwt ? new Date(JSON.parse(window.atob(jwt.split('.')[1]!)).auth_time * 1000) : undefined;
  } catch (err) {
    return undefined;
  }
}

export function getSessionId(jwt?: string): string | undefined {
  try {
    return jwt ? JSON.parse(window.atob(jwt.split('.')[1]!)).sid : undefined;
  } catch (err) {
    return undefined;
  }
}

export function getTipicoCustomerId(jwt?: string): string | undefined {
  try {
    return jwt ? JSON.parse(window.atob(jwt.split('.')[1]!)).tipicoCustomerId : undefined;
  } catch (err) {
    return undefined;
  }
}
