import type { GameFAQ, RobotsContent } from '@src/app';

export function updateMetaTitle(title?: string) {
  document.title = title ?? window.$app.config.metaTitle;
}

export function updateMetaDescription(description?: string) {
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', description ?? window.$app.config.metaDescription);
  }
}

let nextPreloadLinkId = 0;

export function addMetaPreloadLink(href: string, as = 'image', asFirstElement = false) {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = as;
  link.href = href;
  const id = `preload-link-${nextPreloadLinkId++}`;
  link.id = id;
  if (asFirstElement) {
    document.head.insertBefore(link, document.head.firstChild);
  } else {
    document.head.appendChild(link);
  }
  window.$app.logger.log('Added preload link with id:', id, href, `(as ${asFirstElement ? 'first' : 'last'} element)`);

  if (window.$app.config.prerenderMode) {
    return;
  }

  // Schedule removal of the link after 15 seconds
  setTimeout(() => {
    const link = document.getElementById(id);
    if (link) {
      window.$app.logger.log('Removed preload link with id:', id);
      link.remove();
    }
  }, 15_000);
}

function getRobotsTag(): HTMLMetaElement | null {
  const el = document.querySelector('meta[name="robots"]');
  if (!el) {
    window.$app.logger.warn('No existing <meta name="robots"> to adjust.');
    return null;
  }
  return el as HTMLMetaElement;
}

/**
 * https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag#directives
 */
export function updateRobotsTag(content: RobotsContent) {
  const el = getRobotsTag();
  if (el === null) {
    return;
  }
  el.setAttribute('content', content);
}

export function appendOpenGraph(tag: string, content: string, dataSet: string) {
  const meta = document.createElement('meta');
  meta.setAttribute('property', `og:${tag}`);
  meta.setAttribute('content', content);
  meta.setAttribute(`data-${dataSet}`, '');
  document.head.appendChild(meta);
}

export function unloadOpenGraph(dataset: string) {
  const ogMetaTags = document.querySelectorAll(`meta[data-${dataset}]`);
  ogMetaTags.forEach((tag) => {
    document.head.removeChild(tag);
  });
}

export function appendLdJsonFAQ(faqs: GameFAQ[]) {
  const ldJsonScript = document.createElement('script');
  ldJsonScript.type = 'application/ld+json';
  ldJsonScript.id = 'ldJSONFAQ';
  ldJsonScript.innerHTML = `{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [${faqs
      .map(
        (faq) => `
      {
        "@type": "Question",
        "name": ${JSON.stringify(faq.title)},
        "acceptedAnswer": {
          "@type": "Answer",
          "text": ${JSON.stringify(removeMarkup(faq.content))}
        }
      }
    `,
      )
      .join(',')}
    ]
  }`;

  document.body.appendChild(ldJsonScript);
}

export function unloadLdJsonFAQ() {
  const ldJsonScript = document.getElementById('ldJSONFAQ');
  if (ldJsonScript) {
    ldJsonScript.remove();
  }
}

export function cleanupReachText(html: string): string {
  return html
    .replace(/<style[^>]*>.*?<\/style>/gs, '') // remove inline styles
    .replace(/<script[^>]*>.*?<\/script>/gs, '') // remove inline scripts
    .replace(/ style="[^"]*"/g, '') // remove style attribute
    .replace(/<[^>]*>\s*&nbsp;\s*<\/[^>]*>/g, '') // remove tags with only &nbsp;
    .replace(/<[^>]*>\s*<br\s*\/?>\s*<\/[^>]*>/g, ' ') // remove tags with only <br> or <br/>;
    .replace(/<(\w+)[^>]*><\/[^>]*>/g, '') // remove empty tags
    .replace(/<(\w+)[^>]*>\s*<\/\1>/g, ''); // remove tags with only whitespace
}

export function removeMarkup(html: string): string {
  return cleanupReachText(html)
    .replace(/<\/?p[^>]*>/gi, '')
    .replace(/<\/?span[^>]*>/gi, '')
    .replace(/<\/?ul[^>]*>/gi, '')
    .replace(/<li[^>]*>/gi, '')
    .replace(/<\/li[^>]*>/gi, ' ');
}

export function resetRobotsTagToDefault() {
  const el = getRobotsTag();
  if (el === null) {
    return;
  }
  el.setAttribute('content', window.$app.config.metaRobotsDefault);
}

export function getRobotsTagDefault(): RobotsContent {
  const el = getRobotsTag();
  if (el === null) {
    // Fallback to none to avoid indexing by default
    return 'none';
  }
  return el.content as RobotsContent;
}

/**
 * Adds a canonical URL to the head of the document.
 * If a canonical URL already exists, it will be updated.
 */
export function updateCanonicalUrl(): void {
  const currentUrl = new URL(window.location.href);
  const canonicalUrl = `${window.$app.config.canonicalOrigin}${currentUrl.pathname}`;
  const canonicalLinkElement = document.querySelector('link[rel="canonical"]');

  if (canonicalLinkElement) {
    const existingCanonicalUrl = canonicalLinkElement.getAttribute('href');
    if (existingCanonicalUrl !== canonicalUrl) {
      canonicalLinkElement.setAttribute('href', canonicalUrl);
      window.$app.logger.log('Added canonical URL:', canonicalUrl);
    }
  } else {
    const newCanonicalLinkElement = document.createElement('link');
    newCanonicalLinkElement.rel = 'canonical';
    newCanonicalLinkElement.href = canonicalUrl;
    document.head.appendChild(newCanonicalLinkElement);
    window.$app.logger.log('Added canonical URL:', canonicalUrl);
  }
}

/**
 * Adds a meta tag with passed attributes if it already does not exist.
 * Example: addMetaTag({ name: 'apple-itunes-app', content: 'app-id=12345' }) => <meta name="apple-itunes-app" content="app-id=12345">
 */
export function addMetaTag(attributes: { [key: string]: string | undefined }): void {
  const selector = Object.entries(attributes)
    .map(([key, value]) => (value !== undefined ? `[${key}="${value}"]` : ''))
    .join('');

  const existingMetaTag = document.head.querySelector(`meta${selector}`);

  if (!existingMetaTag) {
    const metaTag = document.createElement('meta');
    Object.entries(attributes).forEach(([key, value]) => {
      if (value !== undefined) {
        metaTag.setAttribute(key, value);
      }
    });
    document.head.appendChild(metaTag);
  }
}
