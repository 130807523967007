import { debounce } from 'radash';

export interface IPlayable {
  element: Element;
  isIntersecting?: boolean;
  play: () => Promise<void>;
  stop: () => void;
}

export class VideoOrchestrator {
  private _observer: IntersectionObserver;
  private _playableMap = new Map<Element, IPlayable>();
  private _currentlyPlaying: IPlayable | null = null;
  private _debouncedSortPlayableMap = debounce({ delay: 500 }, this._sortPlayableMap.bind(this));
  private _playbackAllowed = false;

  constructor(options: IntersectionObserverInit) {
    this._observer = new IntersectionObserver(this._handleIntersecting.bind(this), options);
  }

  public addObserverTarget(playable: IPlayable) {
    this._playableMap.set(playable.element, playable);
    this._observer.observe(playable.element);
    this._debouncedSortPlayableMap();
  }

  public disconnect() {
    this._observer.disconnect();
    this._reset();
  }

  public unobserveAll() {
    this._playableMap.forEach((playable) => this._observer.unobserve(playable.element));
    this._reset();
  }

  public playNextElement(targetPlayable: Element) {
    const playableArray = Array.from(this._playableMap.entries());
    const currentIndex = playableArray.findIndex(([element]) => element === targetPlayable);

    if (currentIndex >= 0) {
      const nextPlayable =
        playableArray.slice(currentIndex + 1).find(([, playable]) => playable.isIntersecting) ||
        playableArray.find(([, playable]) => playable.isIntersecting);

      if (nextPlayable) {
        this._playElement(nextPlayable[1]);
      }
    }
  }

  public startPlayback() {
    this._stopAllVideos();
    this._playbackAllowed = true;
    this._managePlayback();
  }

  public stopPlayback() {
    this._playableMap.forEach((playable) => playable.stop());
    this._currentlyPlaying = null;
  }

  private _handleIntersecting(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      const playable = this._playableMap.get(entry.target);
      if (playable) {
        this._playableMap.set(entry.target, { ...playable, isIntersecting: entry.isIntersecting });
      }
    });

    this._managePlayback();
  }

  private _managePlayback() {
    if (!this._playbackAllowed) return;

    const sortedIntersectingEntries = this._getSortedIntersectingEntries();
    const isCurrentlyPlayingVideoOutOfView =
      !this._currentlyPlaying || !this._playableMap.get(this._currentlyPlaying.element)?.isIntersecting;

    if (isCurrentlyPlayingVideoOutOfView) {
      if (sortedIntersectingEntries.length > 0 && sortedIntersectingEntries[0]) {
        this._playElement(sortedIntersectingEntries[0]);
      } else {
        this._stopAllVideos();
      }
    }
  }

  private _getSortedIntersectingEntries(): IPlayable[] {
    return Array.from(this._playableMap.values())
      .filter((playable) => playable.isIntersecting)
      .sort((a, b) => {
        const rectA = a.element.getBoundingClientRect();
        const rectB = b.element.getBoundingClientRect();
        return rectA.top - rectB.top || rectA.left - rectB.left;
      });
  }

  private _playElement(playable: IPlayable) {
    if (this._currentlyPlaying) {
      this._currentlyPlaying.stop();
    }
    this._currentlyPlaying = playable;
    playable.play();
  }

  private _stopAllVideos() {
    this._playableMap.forEach((playable) => playable.stop());
    this._currentlyPlaying = null;
  }

  private _reset() {
    this._stopAllVideos();
    this._playableMap.clear();
    this._currentlyPlaying = null;
  }

  private _sortPlayableMap() {
    const sortedPlayableArray = Array.from(this._playableMap.entries()).sort(([elementA], [elementB]) => {
      const rectA = elementA.getBoundingClientRect();
      const rectB = elementB.getBoundingClientRect();
      return rectA.top - rectB.top || rectA.left - rectB.left;
    });

    this._playableMap = new Map(sortedPlayableArray);
  }
}
