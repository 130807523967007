import type { GetPaymentsPayload } from '@src/_ui-core_/mod-account/types';
import type { Bonus, Campaign } from '@src/_ui-core_/mod-bonus/types';
import type { GetLimitsHistoryPayload } from '@src/_ui-core_/mod-limits';
import { createQuery } from '../../../util/package/url';
import { type ApiDef, fetchSettings } from '../types';
import {
  type BonusSubscription,
  type DepositCashierSession,
  type LimitType,
  type LimitsHistory,
  type LimitsRequestBody,
  type LoginCredentialsBody,
  type LoginOAuthBody,
  type PaymenHistoryItem,
  PrefKey,
  type RecoverPasswordBody,
  type RegistrationPostBody,
  type UpdatePasswordBody,
  type UserActivitySummary,
  type UserPreferences,
  type WalletTransaction,
  type WithdrawalCashierSession,
} from './pam-domain';

// -------------------------------

export async function loginOAuth(url: string, body: LoginOAuthBody): Promise<Response> {
  const casinoUrl = `${url}/v1/api/user/login/oauth`;
  return await fetch(casinoUrl, {
    ...fetchSettings,
    method: 'POST',
    headers: new Headers({
      'content-type': 'application/json',
    }),
    body: JSON.stringify(body),
  });
}

export function loginCredentials(body: LoginCredentialsBody): ApiDef<any> {
  return {
    path: '/v1/api/user/login', // to be changed to /v1/api/user/login/credentials when updated in customer-public-api
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export async function loginGet(url: string, jwt: string): Promise<Response> {
  const pamUrl = `${url}/v1/api/user/login`;
  return await fetch(pamUrl, {
    ...fetchSettings,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${jwt}`,
    },
  });
}

export function loginUpdate(jwt: string): ApiDef<any> {
  return {
    path: '/v1/api/user/login',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export async function sessionRefresh(url: string, jwt: string, active: boolean): Promise<Response> {
  const pamUrl = `${url}/v1/api/user/session/refresh?customerActivity=${active}`;
  return await fetch(pamUrl, {
    ...fetchSettings,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${jwt}`,
    },
  });
}

export async function loginRenew(url: string, jwt: string): Promise<Response> {
  const pamUrl = `${url}/v1/api/user/login/renew`;
  return await fetch(pamUrl, {
    ...fetchSettings,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${jwt}`,
    },
  });
}

export function logoutPam(jwt: string): ApiDef<void> {
  return {
    path: '/v1/api/user/logout',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
  };
}

// -------------------------------

export function recoverPassword(body: RecoverPasswordBody): ApiDef<any> {
  return {
    path: '/v1/api/user/recoverpassword',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ ...body }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function updatePassword(body: UpdatePasswordBody): ApiDef<any> {
  return {
    path: '/v1/api/user/changepassword',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ ...body }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function acknowledgeLifetimeDeposit(jwt: string): ApiDef<any> {
  return {
    path: '/v1/api/payment/threshold/deposit',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
    },
  };
}

export function getClientIp(jwt: string): ApiDef<string> {
  return {
    path: '/v1/api/geolocation/ip',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    mapper: async (r) => (await r.json()).clientIp,
  };
}

export function acceptTerms(jwt: string, version: number): ApiDef<any> {
  return {
    path: '/v1/api/user/terms',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ version }),
    },
  };
}

export function bonusSubscriptions(
  jwt: string,
  options: {
    filter?: 'ALL' | 'ACTIVE' | 'INACTIVE';
    includeExpired?: boolean;
    campaignIds?: string[];
  },
): ApiDef<BonusSubscription[]> {
  (options.campaignIds as any) = options?.campaignIds?.join(',');
  if (!options.campaignIds) {
    delete options.campaignIds;
  }
  return {
    path: `/v1/api/bonus/subscriptions${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
  };
}

export function bonusBonuses(
  jwt: string,
  options: {
    state?: 'ALL' | 'ACTIVE';
  },
): ApiDef<Bonus[]> {
  return {
    path: `/v1/api/bonus/bonuses${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
  };
}

export function bonusCampaigns(
  jwt: string,
  options: {
    ids?: string[];
    tags?: 'casino';
    validity?: 'ALL';
    filter?: 'ALL';
  },
): ApiDef<Campaign[]> {
  (options.ids as any) = options?.ids?.join(',');
  return {
    path: `/v1/api/bonus/campaigns${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
  };
}

export function availableCampaigns(
  jwt: string | undefined,
  options: {
    tags: string;
    validity: 'VALID';
    ids?: string;
  },
): ApiDef<Campaign[]> {
  return {
    path: `/v1/api/bonus/campaigns/all${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: {
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  };
}

export function availableWelcomeCampaigns(options: { tags: string; validity: 'VALID' }): ApiDef<Campaign[]> {
  return {
    path: `/v1/api/bonus/campaigns/welcome${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function subscribeToPromotion(jwt: string, campaignId: string): ApiDef<void> {
  return {
    path: '/v1/api/bonus/subscriptions',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ campaignId }),
    },
    mapper: () => Promise.resolve(void 0),
  };
}

export function claimWelcomePromotion(jwt: string, campaignId: string): ApiDef<void> {
  return {
    path: '/v1/api/bonus/campaigns/welcome',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ campaignId }),
    },
    mapper: () => Promise.resolve(void 0),
  };
}

export function unsubscribeFromPromotion(jwt: string, subscriptionId: string): ApiDef<void> {
  return {
    path: `/v1/api/bonus/campaigns/subscriptions/${subscriptionId}`,
    requestDef: {
      ...fetchSettings,
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    mapper: () => Promise.resolve(void 0),
  };
}

export function unsubscribeFromBonus(jwt: string, bonusId: string): ApiDef<void> {
  return {
    path: `/v1/api/bonus/bonuses/${bonusId}/cancel`,
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: () => Promise.resolve(void 0),
  };
}

export function getDepositSession(jwt: string, redirectUrl: string): ApiDef<DepositCashierSession> {
  return {
    path: '/v1/api/payment/deposit-cashier-sessions',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ returnRedirectUrl: redirectUrl }),
    },
    mapper: (response) => response.json(),
  };
}

export function getWithdrawalSession(jwt: string, redirectUrl: string): ApiDef<WithdrawalCashierSession> {
  return {
    path: '/v1/api/payment/withdrawal-cashier-sessions',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ returnRedirectUrl: redirectUrl }),
    },
    mapper: (response) => response.json(),
  };
}

export function getPreferences(jwt: string): ApiDef<UserPreferences> {
  return {
    path: '/v1/api/user/preferences',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) =>
      response.json().then((bodyObject: { key: string; value: string }[]) => {
        const favoriteGames = bodyObject.find((pref) => pref.key === PrefKey.FAVOURITE_GAMES)?.value;
        const hideBalance = bodyObject.find((pref) => pref.key === PrefKey.SHOW_BALANCE_DISABLED)?.value === 'true';
        const language = bodyObject.find((pref) => pref.key === PrefKey.LANGUAGE)?.value;
        const onboardingFlowsShown = bodyObject.find((pref) => pref.key === PrefKey.CASINO_ONBOARDING)?.value;
        return {
          favoriteGames: new Set(favoriteGames ? favoriteGames.split(',') : []),
          hideBalance,
          language: language ?? '',
          onboardingFlowsShown: new Set(onboardingFlowsShown ? onboardingFlowsShown.split(',') : []),
        };
      }),
  };
}

export function storePreferences(jwt: string, list: { key: string; value: string }[]): ApiDef<boolean> {
  return {
    path: '/v1/api/user/preferences',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
      body: JSON.stringify(list),
    },
    mapper: (response) => Promise.resolve(response.ok),
  };
}

export function claimActivation(jwt: string): ApiDef<{ realityCheck: { scheduledAt: string } }> {
  return {
    path: '/v1/api/user-activation/activated-user',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function showPlayerStatistics(jwt: string): ApiDef<UserActivitySummary> {
  return {
    path: '/v1/api/user-activation/user/requested-activity-summary',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => {
      if (response.status === 204) {
        return Promise.resolve({ ...response, status: response.status });
      }
      return response.json().then((body) => ({ ...body }));
    },
  };
}

export function confirmPlayerStatistics(jwt: string): ApiDef<{ scheduledAt: string }> {
  return {
    path: '/v1/api/user-activation/user/confirmed-activity-summary',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function showRealityCheck(jwt: string): ApiDef<{ scheduledAt: string }> {
  return {
    path: '/v1/api/user-activation/user/requested-reality-check',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function confirmRealityCheck(jwt: string): ApiDef<{ coolDown: { expiredAt: string; scheduledAt: string } }> {
  return {
    path: '/v1/api/user-activation/user/confirmed-reality-check',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function panicModeInitiated(jwt: string): ApiDef<any> {
  return {
    path: '/v1/api/compliance/panic',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => {
      if (response.status === 204) {
        return Promise.resolve({ ...response, status: response.status });
      }

      return response.json().then((body) => ({ ...body }));
    },
  };
}

// IN PROGRESS MOD-REG API CALLS
export function isEmailUnique(email: string): ApiDef<any> {
  return {
    path: '/v1/api/user/TODO',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: email,
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function isUserUnique(email: string, dob: string): ApiDef<any> {
  return {
    path: '/v1/api/user/TODO',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ email, dob }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function checkSchufa(email: string): ApiDef<any> {
  return {
    path: '/v1/api/user/TODO',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(email),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function registrationPost(body: RegistrationPostBody): ApiDef<any> {
  return {
    path: '/v1/api/user/registration',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ ...body }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function registrationMobileNumberCheck(number: string): ApiDef<any> {
  window.$app.logger.log(`/v1/api/user/checkPhoneNumber?number=${number}`);
  return {
    path: `/v1/api/user/checkPhoneNumber?number=${number}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
      }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function getLimits(jwt: string, limitType: LimitType): ApiDef<any> {
  return {
    path: `/v1/api/limits/${limitType}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
  };
}

export function setLimits(jwt: string, limits: LimitsRequestBody) {
  return {
    path: '/v1/api/limits/set',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ ...limits }),
    },
  };
}

export function removeLimit(jwt: string, limits: LimitsRequestBody) {
  return {
    path: '/v1/api/limits/unset',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ ...limits }),
    },
  };
}

export function removeLimitsEnrolmentLimitation(jwt: string) {
  return {
    path: '/v1/api/user/confirm/LUGAS_LIMIT_POPUP',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
    },
  };
}

export function isMigratedDataAvailable(jwt: string, documentType: string): ApiDef<{ status: number }> {
  return {
    path: `/v1/api/download/history/${documentType}/exists`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => {
      if (response.status === 204) {
        return Promise.resolve({ ...response, status: response.status });
      }

      return response.json().then((body) => ({ ...body }));
    },
  };
}

export function getLimitsHistory(jwt: string, options: GetLimitsHistoryPayload): ApiDef<LimitsHistory[]> {
  return {
    path: `/v1/api/limits/history${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => body),
  };
}

export function getPaymentsHistory(jwt: string, options: GetPaymentsPayload): ApiDef<PaymenHistoryItem[]> {
  return {
    path: `/v1/api/payment/payments${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => body.payments),
  };
}

export function getTransactions(
  jwt: string,
  options?: {
    from?: string;
    to?: string;
    limit?: number;
    skip?: number;
  },
): ApiDef<WalletTransaction[]> {
  return {
    path: `/v1/api/wallet/transactions${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function getTransactionDetails(jwt: string, id: string): ApiDef<WalletTransaction> {
  return {
    path: `/v1/api/wallet/transactions/${id}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

// IN PROGRESS API CALLS END
