//@ts-nocheck as native will throw lots of undefined for browser
import { type Dictionary, type Func, isNativeAppAndroid, isNativeAppIos } from '@ui-core/base';

const emptyFunc = () => {
  /* This is intentional */
};

export type UnsubscribeFunc = typeof emptyFunc;

type Message<T> = {
  type: string;
  payload?: T;
};

export interface INativeBridge {
  sendToNative(msg: Message<any>): void;
  getFromNative<T>(msg: Message<any>): Promise<T>;
  subscribeFromNative(type: string, handler: Func): UnsubscribeFunc;
}

export class NativeBridge implements INativeBridge {
  private listeners: Dictionary<Set<Func>> = {};
  private awaiting: Dictionary<boolean> = {};

  constructor() {
    window.nativeToJs = this.fromNativePublishToSubscribers.bind(this);
  }

  public sendToNative(message: Message<any>): void {
    window.$app.logger.log(`sendToNative: ${JSON.stringify(message, null, 2)}`);
    try {
      if (isNativeAppIos()) {
        window.webkit.messageHandlers.jsHandler.postMessage(message);
      } else if (isNativeAppAndroid()) {
        window.jsHandler.postMessage(JSON.stringify(message));
      } else {
        window.$app.logger.error('no message interface installed', null);
      }
    } catch (e) {
      window.$app.logger.error('error during sendToNative', e);
    }
  }

  public getFromNative<T>(message: Message<any>): Promise<T> {
    let unsubscribe: UnsubscribeFunc;
    return new Promise<T>((accept) => {
      unsubscribe = this.subscribeFromNative(message.type, (val) => {
        unsubscribe();
        if (!this.listeners[message.type]) {
          this.awaiting[message.type] = false;
        }
        accept(val);
      });
      if (!this.awaiting[message.type]) {
        this.awaiting[message.type] = true;
        this.sendToNative(message);
      }
    });
  }

  public subscribeFromNative(type: string, handler: Func): UnsubscribeFunc {
    let listeners = this.listeners[type];
    if (!listeners) {
      this.listeners[type] = listeners = new Set();
    }
    listeners.add(handler);
    return () => {
      this.unsubscribe(type, handler);
    };
  }

  // ----------------------------

  private unsubscribe(type: string, handler: Func): void {
    const listeners = this.listeners[type];
    if (listeners?.delete(handler)) {
      if (listeners.size === 0) {
        delete this.listeners[type];
      }
    }
  }

  private fromNativePublishToSubscribers(msg: Message<any>): void {
    const { type, payload } = msg;
    const listeners = this.listeners[type];
    if (!listeners) {
      window.$app.logger.warn(`no listeners registered for type ${type}`);
      return;
    }
    if ((import.meta as any).env.DEV) {
      window.$app.logger.log(
        `received from native type: ${type}; listeners.length: ${listeners.size}; payload: ${JSON.stringify(payload)}`,
      );
    }
    listeners.forEach((listener) => (payload ? listener(payload) : listener()));
  }
}
