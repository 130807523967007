export class ReportingService {
  private readonly reportUrl: string;

  constructor(reportingSetup: ReportingSetup) {
    this.reportUrl = reportingSetup.reportUrl;
  }

  public report(reportPackage: ReportingPackage) {
    // TODO
    // biome-ignore lint/nursery: no-console
    console.log('TODO: report logic here', reportPackage, this.reportUrl);
  }
}

export type ReportingPackage = {
  errorCode: number;
};

export type ReportingSetup = {
  reportUrl: string;
};
