/**
 * Google Tag Manager (GTM) utils
 * Docs: https://developers.google.com/tag-platform/tag-manager/datalayer
 */

import { ClientType } from '../../api';

/**
 * Map client type to GTM values.
 * Strings are defined by the team that manages GTM.
 */
export function GTM_mapClientType(clientType: ClientType): 'Desktop' | 'mWeb' | 'Android Native' | 'iOS Native' {
  switch (clientType) {
    case ClientType.DESKTOP:
      return 'Desktop';
    case ClientType.MWEB:
      return 'mWeb';
    case ClientType.ANDROID_NATIVE:
      return 'Android Native';
    case ClientType.IOS_NATIVE:
      return 'iOS Native';
  }
}

/**
 * Push variable data to GTM dataLayer.
 */
export function GTM_pushVariable(key: string, value: string | number | boolean | null) {
  // console.log('[gtm] setVariable', key, value);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    [key]: value,
  });
}

/**
 * Push page view to GTM dataLayer.
 */
export function GTM_pushPageView(url: string) {
  // console.log('[gtm] pageView', url);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
}

/**
 * Push user id
 */
export function GTM_pushUserId(userId: string | null) {
  if (userId === null) {
    return;
  }
  GTM_pushVariable('uuid', userId);
}

/**
 * Push generic dataLayer payload
 */
export function GTM_pushData(event: Record<string, unknown>) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
}

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
