/**
 * Wait until browser is idle.
 */
export async function idleTick() {
  if ('requestIdleCallback' in window) {
    return new Promise((resolve) => requestIdleCallback(resolve));
  }
  return new Promise((resolve) => requestAnimationFrame(resolve));
}

/**
 * Wait until next repaint.
 */
export async function paintTick(): Promise<number> {
  return new Promise((resolve) => requestAnimationFrame(resolve));
}

/**
 * Wait for n paint ticks (aka animation frames).
 */
export async function paintTicks(n: number): Promise<void> {
  for (let i = 0; i < n; i++) {
    await paintTick();
  }
}

/**
 * Wait until timeout.
 */
export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Wait until next event cycle.
 */
export async function tick(): Promise<void> {
  return await sleep(0);
}
