import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, type Toast } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './ui-toasts.styles';

const CName = 'ui-toasts';

@customElement(CName)
export class UIToasts extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: Array }) toasts: Toast[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    if (this.toasts.length === 0) {
      return nothing;
    }
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="toasts">
        ${repeat(
          this.toasts,
          (toast) => toast.id,
          (toast) => html`
            <ui-toast
              .autoClose="${toast.autoClose}"
              message="${toast.message}"
              toastId="${toast.id}"
              type="${toast.type}"
            ></ui-toast>
          `,
        )}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIToasts;
  }
}
