/**
 * Dispatch a custom event from an element that can be listened to by other elements in the DOM.
 * Shadow DOM boundaries are crossed.
 *
 * @param target The element/target to dispatch the event on
 * @param name The name of the event
 * @param detail The detail to pass with the event
 */
export function dispatchCustomEvent<T>(target: EventTarget, name: string, detail?: T) {
  const event =
    detail !== undefined
      ? new CustomEvent(name, { detail, bubbles: true, composed: true })
      : new Event(name, { bubbles: true, composed: true });
  target.dispatchEvent(event);
}
