export type Currency = 'EUR';

export type Balance = {
  amount: number | null;
  bonus: number;
  currency: Currency;
};

export interface ErrorCode {
  type: string;
  message: string;
  errors?: ErrorCode[];
}

export interface Error {
  status: number;
  errorCode: ErrorCode;
}

export const fetchSettings: RequestInit = {
  cache: 'no-cache',
  referrerPolicy: 'strict-origin',
};

export type ApiDef<T> = {
  path: string;
  requestDef: RequestInit;
  mapper?: (response: Response) => Promise<T>;
};
