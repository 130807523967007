export function convertMsToTime(ms: number): { hours: number; minutes: number; seconds: number } {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return {
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: seconds % 60,
  };
}

/**
 * Example: formatTime(3723000) => '01:02:03'
 */
export function formatTime(ms: number): string {
  const { hours, minutes, seconds } = convertMsToTime(ms);
  const hoursString = hours < 10 ? `0${hours}` : hours;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  const secondsString = seconds < 10 ? `0${seconds}` : seconds;
  return `${hoursString}:${minutesString}:${secondsString}`;
}

/**
 * Example: isDateOlder(new Date('2021-03-01T00:00:00'), 1, 'hour') => true
 */
export function isDateOlder(date: Date, number: number, unit: 'second' | 'minute' | 'hour'): boolean {
  let multiplier;
  switch (unit) {
    case 'second':
      multiplier = 1;
      break;
    case 'minute':
      multiplier = 60;
      break;
    case 'hour':
      multiplier = 3600;
      break;
  }
  const comparisonTime = new Date().getTime() - 1000 * multiplier * number;
  return date.getTime() - comparisonTime < 0;
}

/**
 * Example: getMsBetweenNowAndDate(new Date('2021-03-01T00:00:00')) => 1614556800000
 */
export function getMsBetweenNowAndDate(date: Date): number {
  return date.getTime() - new Date().getTime();
}

/**
 * Example: calcTimeDiffInMinutes(new Date('2021-03-01T00:00:00'), new Date('2021-03-01T01:11:59')) => 71
 */
export function calcTimeDiffInMinutes(date1: Date, date2: Date): number {
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  return Math.floor(diffInMs / (1000 * 60));
}

/**
 * Example: convertTimeToMs('01:02:03') => 3723000
 */
export function convertTimeToMs(time: string): number {
  const [hours, minutes, seconds] = time.split(':');
  return (Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)) * 1000;
}

/**
 * Example: timeAgo(Date.now() - 5000, 'en-US') => 5s ago
 * Example: timeAgo(Date.now() - 3600000, 'de-DE') => vor 1h
 */
export function timeAgo(unixTimestamp: string, locale: 'de-DE' | 'en-US', displayAgoText = true): string {
  const timestamp = Number.parseInt(unixTimestamp);
  const now = Date.now();
  const millisecondsPast = now - timestamp;

  if (millisecondsPast < 0) return '0s';

  const secondsPast = Math.floor(millisecondsPast / 1000);

  const formatWithAgo = (value: number, unit: string, localeEn: string, localeDe: string) => {
    if (locale === 'en-US') {
      return displayAgoText ? `${value}${unit} ${localeEn}` : `${value}${unit}`;
    }
    return displayAgoText ? `vor ${value}${localeDe}` : `${value}${localeDe}`;
  };

  if (secondsPast < 60) {
    return formatWithAgo(secondsPast, 's', 'ago', 's');
  }

  if (secondsPast < 3600) {
    const minutes = Math.floor(secondsPast / 60);
    return formatWithAgo(minutes, 'm', 'ago', 'min');
  }

  if (secondsPast <= 86400) {
    const hours = Math.floor(secondsPast / 3600);
    return formatWithAgo(hours, 'h', 'ago', 'h');
  }

  const days = Math.floor(secondsPast / 86400);
  return formatWithAgo(days, 'd', 'ago', 'd');
}

/**
 * Example: convertTimestampToHourMinute(2024-02-29T14:58:55.352Z) => '15:58'
 */
export function convertTimestampToHourMinute(timestamp: string | number, locale: string): string {
  const date = new Date(timestamp);
  const formattedTime = date.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: '2-digit',
  });
  return formattedTime;
}

/**
 * Example: convertTimestampToDayMonth(2024-02-29T14:58:55.352Z) => '29.02.'
 */
export function convertTimestampToDayMonth(timestamp: string | number, locale: string): string {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
  });
  const trimmedDate = formattedDate.replace(/\.$/, '');
  return trimmedDate;
}

/**
 * Example: convertTimestampToFullDate(2024-02-29T14:58:55.352Z) => '29.02.24'
 */
export function convertTimestampToFullDate(timestamp: string | number, locale: string): string {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
  const trimmedDate = formattedDate.replace(/\.$/, '');
  return trimmedDate;
}

/**
 * Example: thirtyDaysAgo() => '2024-02-16T23:00:00.000Z'
 */
export function thirtyDaysAgo(): string {
  const currentDate: Date = new Date();
  const thirtyDaysAgo: Date = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
  thirtyDaysAgo.setHours(0, 0, 0, 0);
  return thirtyDaysAgo.toISOString();
}

/**
 * Example: getDateDifferenceGroup('2024-01-01','2024-02-01') => '<= 1 month'
 */
export function getDateDifferenceGroup(fromDate: string | undefined, toDate: string | undefined): string {
  if (!fromDate || !toDate) {
    return `Both date should be set. From Date is ${fromDate} and To Date is ${toDate}.`;
  }

  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);

  if (endDate < startDate) {
    window.$app.logger.warn('toDate must be same or after fromDate');
  }

  const yearDiff = endDate.getFullYear() - startDate.getFullYear();
  const monthDiff = endDate.getMonth() - startDate.getMonth();
  const totalMonths = yearDiff * 12 + monthDiff;

  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const dayDiff = endDay - startDay;

  const effectiveMonths = dayDiff > 0 ? totalMonths + 1 : totalMonths;

  if (effectiveMonths <= 12) {
    return `<= ${effectiveMonths} month${effectiveMonths > 1 ? 's' : ''}`;
  }
  return '> 12 months';
}

/**
 * Example: isValidDate('2024-01-01') => true
 * Example: isValidDate('hello') => false
 */
export function isValidDate(dateString: string): boolean {
  const date = new Date(dateString);
  return date instanceof Date && !Number.isNaN(date.getTime());
}
