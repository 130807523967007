import { getDevice } from '../../../util';
import { type ApiDef, fetchSettings } from '../types';
import {
  type Device,
  type GameCount,
  type GameInfoSubset,
  type Lobby,
  type LobbyGame,
  type PanelInfo,
  type RuleSet,
  TileType,
  gameInfoSubsetFields,
} from './gaming-domain';

export function getLobby(name: string): ApiDef<Lobby> {
  return {
    path: `/v1/api/lobbies/${name}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function gamesSearch(
  lobbyId: string,
  search: string,
  displaySet?: string,
  tileType?: TileType,
  limit?: number,
): ApiDef<LobbyGame[]> {
  window.$app.logger.log('game search:', { tileType });
  const params = new URLSearchParams();
  params.append('search', search);
  if (displaySet) params.append('displaySet', displaySet);
  if (tileType) params.append('tileType', tileType);
  if (limit) params.append('limit', limit.toString());
  // Append device type to be able to filter out games in the backend
  params.append('device', getDevice());

  return {
    path: `/v1/api/lobbies/${lobbyId}/games/search?${params}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function gamesList(
  lobbyId: string,
  gameIds: string[],
  displaySet?: string,
  tileType?: TileType,
  limit?: number,
): ApiDef<LobbyGame[]> {
  window.$app.logger.log('game list:', { tileType });
  const params = new URLSearchParams();
  params.append('gameIds', gameIds.join(','));
  if (displaySet) params.append('displaySet', displaySet);
  if (tileType) params.append('tileType', tileType);
  if (limit) params.append('limit', limit.toString());
  return {
    path: `/v1/api/lobbies/${lobbyId}/games/list?${params}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function gamesInfo(lobbyId: string, gameIds?: string[]): ApiDef<GameInfoSubset[]> {
  const params = new URLSearchParams();
  if (gameIds) params.append('gameIds', gameIds.join(','));
  params.append('returnParams', gameInfoSubsetFields.join(','));
  return {
    path: `/v1/api/lobbies/${lobbyId}/games/info?${params}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function gamesFilter(
  lobbyId: string,
  filterIds: string,
  displaySet?: string,
  tileType?: TileType,
  limit?: number,
  ruleSet?: RuleSet,
  includeFilterPanelInfo?: boolean,
): ApiDef<LobbyGame[]> {
  window.$app.logger.log('game filter:', { tileType });
  const params = new URLSearchParams();
  params.append('filterIds', filterIds);
  if (displaySet) params.append('displaySet', displaySet);
  if (tileType) params.append('tileType', tileType);
  if (limit) params.append('limit', limit.toString());
  if (includeFilterPanelInfo) params.append('includeFilterInfo', includeFilterPanelInfo.toString());
  if (ruleSet) params.append('ruleSet', JSON.stringify(ruleSet));
  return {
    path: `/v1/api/lobbies/${lobbyId}/games/filter?${params}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function gamesUser(
  jwt: string,
  lobbyId: string,
  filterIds: string,
  displaySet?: string,
  tileType?: TileType,
  limit?: number,
  includeFilterPanelInfo?: boolean,
): ApiDef<LobbyGame[]> {
  const params = new URLSearchParams();
  params.append('filterIds', filterIds);
  if (displaySet) params.append('displaySet', displaySet);
  if (tileType) params.append('tileType', tileType);
  if (limit) params.append('limit', limit.toString());
  if (includeFilterPanelInfo) params.append('includeFilterInfo', includeFilterPanelInfo.toString());
  return {
    path: `/v1/api/lobbies/${lobbyId}/games/user?${params}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
  };
}

export function gaming_getGameInfo(
  lobbyId: string,
  gameId?: string,
  slug?: string,
  displaySet?: string,
  tileType = TileType.P1,
): ApiDef<any> {
  const params = new URLSearchParams();
  params.append('idType', gameId ? 'id' : 'slug');
  if (displaySet) params.append('displaySet', displaySet);
  if (tileType) params.append('tileType', tileType);
  const id = gameId ?? slug;
  return {
    path: `/v1/api/lobbies/${lobbyId}/games/${id}/info?${params.toString()}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function getGameSimilar(
  lobbyId: string,
  gameId: string,
  displaySet?: string,
  tileType?: string,
  device?: string,
): ApiDef<LobbyGame[]> {
  const params = new URLSearchParams();
  if (displaySet) params.append('displaySet', displaySet);
  if (tileType) params.append('tileType', tileType);
  if (device) params.append('device', device);
  return {
    path: `/v1/api/lobbies/${lobbyId}/games/${gameId}/similar?${params.toString()}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function getGamesForTags(
  lobbyId: string,
  tagCategory: string,
  tags: string,
  displaySet?: string,
  tileType?: string,
  device?: string,
  limit?: number,
): ApiDef<LobbyGame[]> {
  const params = new URLSearchParams();
  params.append('tagCategory', tagCategory);
  params.append('tags', tags);
  if (displaySet) params.append('displaySet', displaySet);
  if (tileType) params.append('tileType', tileType);
  if (device) params.append('device', device);
  if (limit) params.append('limit', limit.toString());

  return {
    path: `/v1/api/lobbies/${lobbyId}/games/tag?${params.toString()}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function getPanelInfo(lobbyId: string, gameStudioIds?: string, tags?: string): ApiDef<PanelInfo> {
  const params = new URLSearchParams();
  if (gameStudioIds) params.append('gameStudioIds', gameStudioIds);
  if (tags) params.append('tags', tags);
  return {
    path: `/v1/api/lobbies/${lobbyId}/panelInfo?${params.toString()}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}

export function gameCount(lobbyId: string, filterIds: string[], device?: Device): ApiDef<GameCount[]> {
  window.$app.logger.log('game count. lobbyId:', lobbyId, 'filterIds:', filterIds, 'device:', device);
  const params = new URLSearchParams();
  params.append('filterIds', filterIds.join(','));
  if (device) params.append('device', device);
  return {
    path: `/v1/api/lobbies/${lobbyId}/filterInfo?${params}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
    },
  };
}
