export type Func = (...args: any[]) => any;

export type Callback<T> = (...val: T[]) => void;

export type CallbackFn<T> = (val: T) => void;

export interface Dictionary<T> {
  [key: string]: T;
}

export enum MessageTypes {
  Error = 'error',
  Success = 'success',
  Default = 'default',
  Info = 'info',
  Warning = 'warning',
}
