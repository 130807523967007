import type { ApiDef } from '../types';

export function getItems<T>(collection: string, query?: string): ApiDef<Array<T>> {
  window.$app.logger.log('getItems', collection, 'request…');
  return {
    path: `/items/${collection}${q(query)}`,
    requestDef: {
      method: 'GET',
    },
    mapper: (response) => response.json().then((body) => body.data),
  };
}

export function getItemById<T>(collection: string, id: string, query?: string): ApiDef<T> {
  return {
    path: `/items/${collection}/${id}${q(query)}`,
    requestDef: {
      method: 'GET',
    },
    mapper: (response) => response.json().then((body) => body.data),
  };
}

export async function getAsset(url: string, id: string, query?: string): Promise<Blob> {
  const contentUri = getAssetUrl(url, id, query);
  const response = await fetch(contentUri);
  return await response.blob();
}

export function getAssetUrl(url: string, id: string, query?: string): string {
  return `${url}/assets/${id}${q(query)}`;
}

// ------------------------

function q(query: string | undefined) {
  return query ? `?${query}` : '';
}
