// ----------------------------
// NATIVE APP INFO
// ----------------------------

import { ClientType, Device } from '../../api';

export interface NativeAppInfo {
  nativeAppOs: 'ios' | 'android' | string;
  nativeAppOsVersion: string;
  nativeAppName: string;
  nativeAppVersion: string;
  nativeAppBundleId: string;
}

export function getNativeAppInfo(): NativeAppInfo | null {
  // User-Agent: Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CustomContent:iOS/17.0.1/TipicoGamesUE/1.0/tipico.games.eu
  const userAgent = window.navigator.userAgent;
  const split = userAgent.split('CustomContent:');
  if (split.length === 2) {
    const customContent = split[1]!;
    const tokens = customContent.split('/');
    if (tokens.length === 5) {
      return {
        nativeAppOs: tokens[0]!.toLowerCase(),
        nativeAppOsVersion: tokens[1]!,
        nativeAppName: tokens[2]!,
        nativeAppVersion: tokens[3]!,
        nativeAppBundleId: tokens[4]!,
      };
    }
  }
  return null;
}

// ----------------------------
// OS
// ----------------------------

export function isIos(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipod|ipad/.test(userAgent);
}

export function isAndroid() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /android/.test(userAgent);
}

export function isMacintosh(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /macintosh/.test(userAgent);
}

// ----------------------------
// DEVICE
// ----------------------------

export function isNativeAppIos(): boolean {
  // @ts-expect-error
  return !!window.webkit?.messageHandlers?.jsHandler?.postMessage;
}

export function isNativeAppAndroid() {
  // @ts-expect-error
  return !!window.jsHandler;
}

export function isNativeLegacyAndroid() {
  // @ts-expect-error
  return !!window.JSInterface;
}

export function isNativeApp(): boolean {
  return isNativeAppIos() || isNativeAppAndroid() || isNativeLegacyAndroid();
}

export function getNativeOsType(): 'ios' | 'android' | 'unknown' {
  if (isNativeAppIos()) {
    return 'ios';
  }

  if (isNativeAppAndroid() || isNativeLegacyAndroid()) {
    return 'android';
  }

  window.$app.logger.warn('Native OS is not supported');
  return 'unknown';
}

// export function isTablet() {
//   return /ipad|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase());
// }

// ----------------------------
// BROWSER
// ----------------------------

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isChrome(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /chrome/.test(userAgent) || /crios/.test(navigator.userAgent.toLowerCase());
}

// ----------------------------
// TOUCHSCREEN
// ----------------------------

export function isTouchscreen() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

// ----------------------------
// HELPERS
// ----------------------------

export function isMobileDevice() {
  return isIos() || isAndroid();
}

export function isAppleDevice(): boolean {
  return isIos() || isMacintosh();
}

export function isMWeb(): boolean {
  return !isNativeApp() && isMobileDevice();
}

export function isMWebIos(): boolean {
  return !isNativeApp() && isIos();
}

export function isMWebAndroid(): boolean {
  return !isNativeApp() && isAndroid();
}

// ----------------------------
// ??
// ----------------------------

export function isBrowserMainThread() {
  const global = getGlobal();
  return typeof global.window !== 'undefined' && typeof global.document !== 'undefined';
}

export function getGlobal() {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw new Error('global object not found');
}

export function logEnvironment() {
  const obj = {} as any;
  obj.userAgent = navigator.userAgent;
  obj.isNativeApp = isNativeApp();
  obj.isIos = isIos();
  obj.isAndroid = isAndroid();
  obj.isMobileDevice = isMobileDevice();
  obj.isMWeb = isMWeb();
  obj.isMWebIos = isMWebIos();
  obj.isMWebAndroid = isMWebAndroid();
  window.$app.logger.log('Environment:', obj);
}

// isDesktopView() removed.
// Better solution:
// Solution for HTML: <Breakpoint min/max={BreakpointKey.large}>
// Solution for CSS: ${({ theme }) => theme.media.up/down(BreakpointKey.large)`
// Solution for Code: const isDesktop = useBreakpoint(BreakpointKey.large);

export function getOS(): 'Windows' | 'Mac' | 'Other' {
  const os = navigator.userAgent;
  if (os.search('Windows') !== -1) {
    return 'Windows';
  }
  if (os.search('Mac') !== -1) {
    return 'Mac';
  }
  return 'Other';
}

/**
 * Get current device type
 * @returns Device
 */
export function getDevice(): Device {
  if (isIos()) {
    return Device.IOS;
  }
  if (isAndroid()) {
    return Device.ANDROID;
  }
  return Device.DESKTOP;
}

export function getClientType(): ClientType {
  if (isNativeApp()) {
    return isIos() ? ClientType.IOS_NATIVE : ClientType.ANDROID_NATIVE;
  }
  return isMWeb() ? ClientType.MWEB : ClientType.DESKTOP;
}
