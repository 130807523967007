import { css } from 'lit';

export const styles = css`
  .toasts {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    position: sticky;
    width: 100%;
    z-index: var(--z-index-toasts);
  }
`;
