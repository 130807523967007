import { formatNumber } from '../../../util';

export enum TileType {
  P1 = 'p1',
  P2 = 'p2',
  L1 = 'l1',
}

export enum LabelValue {
  EXCLUSIVE = 'EXCLUSIVE',
  NEW = 'NEW',
  TOP10 = 'TOP10',
}

export enum FetchPriority {
  AUTO = 'auto',
  HIGH = 'high',
  LOW = 'low',
}

export enum ImageLoading {
  EAGER = 'eager',
  LAZY = 'lazy',
}

export enum ImageDecoding {
  ASYNC = 'async',
  SYNC = 'sync',
}

export enum Device {
  DESKTOP = 'desktop',
  ANDROID = 'android',
  IOS = 'ios',
}

export enum ClientType {
  DESKTOP = 'desktop',
  ANDROID_NATIVE = 'android_native',
  IOS_NATIVE = 'ios_native',
  MWEB = 'mweb',
}

export enum Orientation {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

export type Studio = {
  id: string;
  img: string /* ID of directus asset */;
  name: string;
  filterId: string;
  icon?: string;
};

export type LobbyCategoryDisplay = {
  rank: boolean /* show numbers next to tiles */;
  rows: number;
  tile: TileType;
  limit: number;
  showVideo?: boolean /* defaults to `true` from BE */;
};

export type GameBase = {
  id: string /* example: ogs:70476 => path: /game/70476 */;
  img: string | null /* ID of directus asset */;
  vid?: string /* ID of directus asset */;
  title: string;
};

export class GameContent {
  description?: string | null;
  faqIds?: string[];
  features: string[] | null;
  hasFunMode?: boolean;
  hitFrequency?: number;
  id: string;
  img: string;
  maxBet?: string;
  maxPayout?: string;
  maxWin?: number;
  minBet?: string;
  noIndex?: boolean;
  numBetMax?: number;
  numBetMin?: number;
  numReels?: number;
  numWinlines?: number;
  rtp?: number;
  slug: string;
  studio?: string;
  tags?: GameTag[];
  themes: string[] | null;
  title: string;
  vid: string;
  volatility?: number;

  public static fromGameDetails(gameDetails: GameDetails): GameContent {
    return { ...gameDetails } as GameContent;
  }
}

export type GameTag = {
  id: string;
  title: string;
};

export type LobbyGame = GameBase & {
  biggestWin: number | undefined;
  exclude?: {
    devices?: Device[];
    orientations?: Orientation[];
  };
  gameStudioId: string | undefined;
  imgMobile: string | undefined /* ID of directus asset */;
  vidMobile?: string /* ID of directus asset */;
  labels: LabelValue[] | undefined;
  maintenance: boolean | undefined;
  themes: string[] | undefined;
  features: string[] | undefined;
  latestWinAmount: string; // exists, if this field is present latestWinMultiplier & latestWinTime
  latestWinMultiplier: string;
  latestWinTime: string;
  slug: string;
};

export type GamesFilter = {
  filterId: string;
  userBased: boolean;
};

export type PageDisplay = {
  showLabels: boolean;
  tile: TileType;
  showSearchFilters: boolean;
};

export type LobbyCategory = GamesFilter & {
  display: LobbyCategoryDisplay;
  displayMobile: LobbyCategoryDisplay | undefined;
  displaySet: string;
  games: LobbyGame[] | undefined; // If `games` is undefined this category is a lazy category and the games must be fetched via /category?categoryId=super endpoint. App.content.filterGames()
  page: PageDisplay;
  pageMobile: PageDisplay;
  title: string;
  cards?: string[];
};

export type CategoryBarFilter = GamesFilter & {
  content: {
    assets: {
      img: string;
    };
    kind: string;
    name: string;
  };
  page: PageDisplay;
  pageMobile: PageDisplay;
  title: string;
};

export enum CategoryRequestedBy {
  FILTER_BAR = 'bar',
  VIEW_ALL = 'view',
}

export type SearchPanel = GamesFilter & {
  page: PageDisplay;
  pageMobile: PageDisplay;
  title: string;
};

export type GameSidePanel = GamesFilter & {
  page: PageDisplay;
  pageMobile: PageDisplay;
  position: 'LEFT' | 'RIGHT';
  title: string;
};

export type Lobby = {
  categories: LobbyCategory[];
  categoryBar: CategoryBarFilter[];
  gameSidePanels: GameSidePanel[];
  id: string;
  search: SearchPanel;
  searchCategories: LobbyCategory[];
  searchFilters: SearchFilter[];
  studios: Studio[];
  searchTags: Tag[];
};

export type TagCategory = 'theme' | 'feature' | null;

export type Tag = {
  category: TagCategory;
  name: string;
  filterId: string;
};

export type SearchFilter = {
  title: string;
  filters: SearchInnerFilter[];
};

export type SearchInnerFilter = {
  groupName: string;
  filterId: string;
  title: string;
  icon: string;
  selected: boolean;
  disabled: boolean;
};

export type GameCount = {
  [key: string]: number;
};

export class GameDetails {
  description?: string;
  hasFunMode?: boolean;
  id: string;
  img: string;
  slug: string;
  themes?: string[];
  title: string;

  vid?: string;

  private readonly maxBet?: number;
  private readonly minBet?: number;
  private readonly rtp?: number;
  private readonly studio?: string;

  constructor(
    payload: any,
    private moneyFormatter: (val: number) => string,
  ) {
    for (const key in payload) {
      // @ts-expect-error
      this[key] = payload[key];
    }
  }

  public getMaxBet(): string {
    // 10000 -> $100.0
    return this.maxBet ? this.moneyFormatter(this.maxBet) : '-';
  }

  public getMinBet(): string {
    // 20 -> $0.20
    return this.minBet ? this.moneyFormatter(this.minBet) : '-';
  }

  public getRtp(): number | undefined {
    return this.rtp;
  }

  // 96.22 -> 96,22%
  public getRtpString(): string {
    return this.rtp !== undefined ? `${formatNumber(this.rtp)}%` : '-';
  }

  public getStudio(): string {
    return this.studio ?? '-';
  }
}

export type PanelInfo = Array<PanelInfoItem>;

export type PanelInfoItem = {
  title: string;
  filters: Array<{
    filterId: string;
    title: string;
    icon: string;
  }>;
};

export type Rule = {
  left: string;
  op: string;
  right: Array<string>;
};

export type RuleSet = {
  rules: Array<Rule>;
};

export type GameInfo = {
  bundleId?: string;
  created?: Date;
  description?: string;
  devices?: Device[];
  displaySets?: unknown;
  faqIds?: string[];
  features?: string[];
  gameId?: string;
  gameStudioId?: string;
  gameType?: string;
  genres?: string[];
  hasFunMode?: boolean;
  hitFrequency?: number;
  integration?: string;
  integrationGameId?: string;
  jurisdictions?: string[];
  languages?: string;
  launchId?: string;
  launchIdDesktop?: string;
  launchIdMobile?: string;
  maxBet?: number;
  maxPayout?: number;
  maxWin?: number;
  mechanics?: string[];
  minBet?: number;
  modified?: Date;
  noIndex?: boolean;
  numBetMax?: number;
  numBetMin?: number;
  numReels?: number;
  numWinlines?: number;
  orientations?: Orientation[];
  regId?: string;
  rtp?: number;
  slug?: string;
  studio?: string;
  themes?: string[];
  title?: string;
  volatility?: number;
};

export const gameInfoSubsetFields = ['gameId', 'title', 'rtp', 'studio', 'bundleId'];
export type GameInfoSubset = Readonly<Required<Pick<GameInfo, 'gameId' | 'title' | 'rtp' | 'studio' | 'bundleId'>>>;
