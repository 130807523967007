import type { LimitTerm } from '@src/_ui-core_/mod-limits/types';
import type { TDateISO } from '../../../date.extensions';
import type { Balance } from '../types';

export enum LimitedFlag {
  BOT = 'BOT',
  TEST = 'TEST',
  COOLOFF = 'COOLOFF',
  NJ_LIFETIME_DEPOSIT_LIMIT = 'NJ_LIFETIME_DEPOSIT_LIMIT',
  SELF_EXCLUDED = 'SELF_EXCLUDED',
  TAC_REQUIRED = 'TAC_REQUIRED',
  VERIFYING = 'VERIFYING',
  W2G_REVIEW = 'W2G_REVIEW',
  LUGAS_LIMIT_POPUP = 'LUGAS_LIMIT_POPUP',
}

export enum LoginErrorType {
  SESSION_LIMIT_REACHED = 'pam:session-limit-reached',
  USER_BLOCKED = 'pam:user-blocked',
  USER_SUSPENDED = 'pam:user-suspended',
}

export type LoginResponse = {
  balance: Balance;
  jwt: string;
  lastAcceptedTerms: number;
  lastLogin?: TDateISO;
  limited: LimitedFlag[];
  serverTime: TDateISO;
};

export type LoginCredentialsBody = {
  email: string;
  password: string;
  username?: string;
  ssoToken?: string;
  ssoTokenLabel?: string;
};

export type LoginOAuthBody = {
  provider: string;
  code: string;
};

export type RecoverPasswordBody = {
  email: string;
  dateOfBirth?: string;
};

export type UpdatePasswordBody = {
  oldPassword: string;
  newPassword: string;
};

export type RegistrationPostBody = {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  dateOfBirth: string;
  city: string;
  zip: string;
  country: string;
  street: string;
  houseNumber: string;
  addressAddon: string;
  phone: string;
  language: string;
  password: string;
};

export type BonusSubscription = {
  active: boolean;
  campaignId: string;
  expiration: string;
  id: string;
};

export type BonusCampaign = {
  externalConfig: any;
  subscriptionConfig: {
    event: string;
  };
  id: string;
  restriction: 'PUBLIC' | 'PRIVATE' | 'RESTRICTED' | 'WELCOME';
};

export type DepositCashierSession = {
  balance: string;
  currency: string;
  maxAmount?: string;
  paymentBaseUrl: string;
  paymentSessionId: string;
};

export type WithdrawalCashierSession = {
  balance: string;
  currency: string;
  maxAmount?: string;
  paymentBaseUrl: string;
  paymentSessionId: string;
};

export enum PrefKey {
  FAVOURITE_GAMES = 'FAVOURITE_GAMES',
  SHOW_BALANCE_DISABLED = 'SHOW_BALANCE_DISABLED',
  LANGUAGE = 'LANGUAGE',
  CASINO_ONBOARDING = 'CASINO_ONBOARDING',
}

export type UserPreferences = {
  favoriteGames: Set<string>;
  hideBalance: boolean;
  language: string;
  onboardingFlowsShown?: Set<string>;
};

export type Limit = {
  limit: number;
  actual: number;
  next?: number;
  createdAt: string;
  modifiedAt?: string;
  activation?: string;
  flippingTime?: string;
  activationTime?: string;
};

export type Limits = {
  userId: string;
  daily: Limit;
  weekly: Limit;
  monthly: Limit;
  onetime: Limit;
};

export type LimitsEventBody = {
  limitType: LimitType;
  daily?: number;
  monthly?: number;
  weekly?: number;
  term: string;
};

export type LimitsRequestBody = {
  limitType: LimitType;
  daily?: number;
  monthly?: number;
  weekly?: number;
};

export type LimitRemoveBody = {
  limitType: LimitType;
  limitPeriod: LimitTerm;
};

export enum LimitType {
  LOSS = 'loss',
  SPENDING = 'spending',
}

export type LimitsHistory = {
  id: string;
  limitType: LimitType;
  period: LimitTerm;
  updatedValue: number;
  updatedByUser: string;
  activatedAt: string;
  referenceDate: string;
  status: LimitHistoryStatus;
  userId: string;
};

export enum LimitHistoryStatus {
  REQUESTED = 'requested',
  WILL_APPLY = 'willApply',
  APPLIED = 'applied',
}

export type TransactionDetails = {
  context: string;
  action: string;
  game?: string;
  bonusId?: string;
  campaignId?: string;
};

export type TransactionBalanceDetail = {
  start: number;
  end: number;
};

export enum WalletTransactionType {
  WINNING = 'WINNING',
  WAGER = 'WAGER',
}

export type WalletTransaction = {
  id: string;
  source: string;
  destination: string;
  ref: string;
  uref: string;
  details: TransactionDetails;
  amount: number;
  currency: string;
  state: string;
  type: WalletTransactionType;
  created: string;
  modified: string;
  balance?: TransactionBalanceDetail;
};

export type WalletBlockedAmount = {
  amount: number;
};

export enum PaymentType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  REFUND = 'REFUND',
  BONUS_WITHDRAWAL = 'BONUS_WITHDRAWAL',
}

export enum PaymentStatus {
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type PaymenHistoryItem = {
  paymentId: string;
  type: PaymentType;
  status: PaymentStatus;
  initiatedAt: string;
  currency: string;
  amount: number;
  initialBalance: number;
  finalBalance: number;
  referenceId: string;
  transactionId: string;
};

export type UserActivitySummary = {
  scheduledAt: string;
  currency: string;
  stake: number;
  profit: number;
  balance: number;
  status: number;
};
