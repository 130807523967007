export type UserProfile = {
  email?: string;
  username?: string;
  screenName?: string;
  login?: string;
  firstName?: string; //currently not provided
  lastName?: string; //currently not provided
};

// ------------------------

export type Channel = 'MOBILE' | 'DESKTOP' | 'IOS' | 'ANDROID' | 'MWEB';

export type GameStartRequest = {
  provider: string;
  gameId: string;
  channel: Channel;
  language: string;
  currency: string;
};

export type LaunchConfig = {
  serverUrl?: string;
  operatorId?: string;
  launchId?: string;
  launchUrl?: string;
  bundleId?: string;
};

export type PlayForRealInfo = {
  token: string;
  accountId: string;
};

// ------------------------

export type GameLaunchInfo = {
  currency: string;
  channel: string;
  language: string;
  lobbyUrl: string;
  launchConfig: LaunchConfig;
  playForReal?: PlayForRealInfo;
};

// ------------------------

export enum GameHistoryType {
  MONEY = 'MONEY',
  FREEROUND = 'FREEROUND',
}
export enum GameHistoryStatus {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export type GameHistoryItem = {
  roundId: string;
  gameId: string;
  userId: string;
  startedAt: string;
  startedAtBalance: number;
  lastModifiedAt: string;
  lastModifiedAtBalance: number;
  currency: string;
  netWin: number;
  type: GameHistoryType;
  status: GameHistoryStatus;
  winTotal: number;
  betTotal: number;
};

export type RgsGameLaunch = {
  gameId: string;
  currency: string;
  channel: 'MOBILE' | 'DESKTOP';
  language: string;
  product: string;
  device: 'ios' | 'android' | 'desktop';
};
