import { memo } from 'radash';

type ItemSupplier<T> = (itemId?: string) => Promise<T>;

type MatcherType = (st1: string, str2: string) => boolean;

const Equals: MatcherType = (st1: string, st2: string) => {
  return st1 === st2;
};

const Includes: MatcherType = (st1: string, st2: string) => {
  return st1.includes(st2);
};

const StartsWith: MatcherType = (st1: string, st2: string) => {
  return st1.startsWith(st2);
};

export const Matcher = {
  Equals,
  Includes,
  StartsWith,
};

export class ItemCache<T> {
  private items: Map<string, T> = new Map();
  private supplierMap: Map<string, ItemSupplier<T>> = new Map();

  public async get(itemId: string, itemSupplier: ItemSupplier<T>): Promise<T> {
    let item = this.items.get(itemId);
    if (!item) {
      let supplier = this.supplierMap.get(itemId);
      if (!supplier) {
        supplier = memo(itemSupplier);
        this.supplierMap.set(itemId, supplier);
      }
      try {
        item = await supplier(itemId);
        this.items.set(itemId, item);
        return item;
      } catch (err) {
        // Delete key from map. Otherwise we store the failed request.
        this.supplierMap.delete(itemId);
        window.$app.logger.warn(`'${itemId}' request failed.`, err);
        throw new Error('request failed');
      }
    } else {
      window.$app.logger.log('get from cache', itemId);
      return item;
    }
  }

  public invalidate(itemId: string, match: MatcherType): void {
    window.$app.logger.log('invalidate cache of itemId', itemId, 'using matcher:', match);
    this.items.forEach((_value, key, map) => {
      if (match(key, itemId)) {
        window.$app.logger.log('removed from cache', key);
        map.delete(key);
      }
    });
    this.supplierMap.forEach((_value, key, map) => {
      if (match(key, itemId)) {
        window.$app.logger.log('removed from supplier', key);
        map.delete(key);
      }
    });
  }

  public has(cacheKey: string) {
    return this.items.has(cacheKey);
  }

  public getOrElse(itemId: string, other: T | null) {
    return this.items.get(itemId) ?? other;
  }

  // ----------------------------------

  public set(itemId: string, item: T): void {
    this.items.set(itemId, item);
  }

  public size(): number {
    return this.items.size;
  }
}
