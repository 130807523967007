import type { GetGameHistoryPayload } from '@src/_ui-core_/mod-account/types';
import { createQuery } from '../../../util/package/url';
import { type ApiDef, type Balance, fetchSettings } from '../types';
import type { GameHistoryItem, GameStartRequest, LaunchConfig, PlayForRealInfo, UserProfile } from './casino-domain';

export function getProfile(jwt: string): ApiDef<UserProfile> {
  return {
    path: '/v1/api/user/profile/details',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => body.attributes),
  };
}

export function getBalance(jwt: string): ApiDef<Balance> {
  return {
    path: '/v1/api/user/balance',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => body.balance),
  };
}

export async function renewToken(url: string, oldToken: string): Promise<Response> {
  const casinoUrl = `${url}/v1/api/renew`;
  return await fetch(casinoUrl, {
    ...fetchSettings,
    method: 'GET',
    headers: new Headers({
      authorization: `Bearer ${oldToken}`,
    }),
  });
}

export function logout(jwt: string): ApiDef<void> {
  return {
    path: '/v1/api/logout',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
  };
}

export function startGameReal(request: GameStartRequest, jwt: string): ApiDef<LaunchConfig & PlayForRealInfo> {
  return {
    path: '/v1/api/game/start',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
      body: JSON.stringify(request),
    },
  };
}

export function startGameFun(request: GameStartRequest): ApiDef<LaunchConfig> {
  return {
    path: '/v1/api/game/fun',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
      }),
      body: JSON.stringify(request),
    },
  };
}

// export function getFreespinsPromotions(jwt: string): ApiDef<Array<FreespinsPromo>> {
//   return {
//     path: '/v1/api/freespins',
//     requestDef: {
//       ...fetchSettings,
//       method: 'GET',
//       headers: new Headers({
//         'content-type': 'application/json',
//         authorization: `Bearer ${jwt}`,
//       }),
//     },
//   };
// }

export function getGameHistory(jwt: string, options?: GetGameHistoryPayload): ApiDef<GameHistoryItem[]> {
  return {
    path: `/v1/api/history${createQuery(options)}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => ({ ...body })),
  };
}

export function ottCreate(jwt: string, data: any): ApiDef<string> {
  return {
    path: '/v1/api/ott/create',
    requestDef: {
      ...fetchSettings,
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
      body: JSON.stringify(data),
    },
    mapper: (response) => response.json().then((body) => body.token),
  };
}

export function ottRedeem(token: string): ApiDef<any> {
  return {
    path: `/v1/api/ott/redeem?token=${token}`,
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
      }),
    },
  };
}

export function initiatePanicMode(jwt: string): ApiDef<void> {
  return {
    path: '/v1/api/user/self-exclusion/panic',
    requestDef: {
      ...fetchSettings,
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: () => Promise.resolve(void 0),
  };
}

export function getZiqniMemberToken(jwt: string): ApiDef<string> {
  return {
    path: '/v1/api/ziqni/member-token',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${jwt}`,
      }),
    },
    mapper: (response) => response.json().then((body) => body.token),
  };
}
