export function createQuery(obj: any) {
  const string = Object.entries(obj)
    .filter(([key, val]) => key !== null && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  return string.length ? `?${string}` : '';
}

/**
 * Remove URL query parameters like any potential path tokens
 */
export function removeQueryParamsFromCurrentUrl(state: any = null) {
  const urlObj = new URL(window.location.href);
  const searchParams = new URLSearchParams(window.location.search);
  // Restore prerender flag in URL to enable Lighthouse checks in prerender mode.
  const isPrerender = searchParams.has('prerender');
  const url = `${urlObj.origin}${urlObj.pathname}${isPrerender ? '?prerender' : ''}`;
  history.replaceState(state, '', url);
}
